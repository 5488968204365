:root {
  /* Colors */
  --color-black: #212529;
  --color-gray-dark: #707070;
  --color-gray: #D8D8D8;
  --color-gray-light: #ececec;
  --color-gray-extra-light: #f6f6f6;
  --color-white: #fff;

  --color-primary-red: 91;
  --color-primary-green: 26;
  --color-primary-blue: 148;

  --color-primary: rgb(
    var(--color-primary-red),
    var(--color-primary-green),
    var(--color-primary-blue)
  );
  --color-primary-darkened: #280061;
  --color-primary-lightened: #8E4DC7;
  --color-secondary: #D7CA2A;
  --color-light: #DDEDFF;

  --brand-danger: #DE4443;
  --brand-disabled: var(--color-gray-light);
  --brand-success: #00B060;
  --brand-warning: #FFA400;
  --brand-success-light: #4ac68e;


  /* Typography */
  --font-family-base: 'Lato', sans-serif;
  --font-family-headings: 'Lato', sans-serif;
  --font-family-handwriting: 'Indie Flower', cursive;

  /* Nav */
  --nav-height: 3.5rem;
  --nav-height--lg: 5rem;

  /* Spinner */
  --spinner-color: var(--color-primary);
  --spinner-color-ring: rgba(
    var(--color-primary-red),
    var(--color-primary-green),
    var(--color-primary-blue),
    .3
  );

  /* Links */
  --link-color: #4A90E2;
  --link-color--hover: #175DAF;

  /* Global Alert */
  --global-alert-height: 3rem;
  --share-banner-height: 5rem;

  /* Lead Bar */
  --lead-bar-height: 8rem;

  /* CSC: Header */
  --header-background: var(--color-primary);
  --header-color: var(--color-light);

  /* CSC: CardList */
  --card-list-width: 540px;

  /* MAPTV: Header */
  --maptv-header-height: 64px; /* Set fixed height so we can calc step offsets */

  --color-election-center-bg: #302f2f;
  
  /* Share Scene */
  --share-image-background: #326272;
  --share-image-text: #e39674;
  --share-image-label-text: #ffffff;
}

@media (min-width: 576px) {
  :root {
    --maptv-header-height: 112px;
  }
}

.AddressForm .PlaceField__Hint {
  color: #000;
  font-family: MetamorBit, Courier New, courier, monospace;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 140%;
}
@media (min-width: 768px) {
  .AddressForm small.PlaceField__Hint {
    font-size: 0.875rem;
  }
}
@media (min-width: 1024px) {
  .AddressForm small.PlaceField__Hint {
    font-size: 0.875rem;
  }
}
.AddressForm .PlaceField__dropdown-container {
  border-left: 6px solid #ffbf10;
  border-right: 6px solid #ffbf10;
}
.AddressForm .PlaceField__dropdown-footer {
  border-bottom: 6px solid #ffbf10;
  margin-top: -3px;
}
.AddressForm .PlaceField__suggestion {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0;
}
.AddressForm .PlaceField__suggestion span {
  border-bottom: 2px solid #ffbf10;
  display: block;
  padding: 0.625rem 1.25rem;
  width: 90%;
}
.AddressForm .PlaceField__suggestion:last-of-type span {
  border-bottom: 0;
}
.AddressForm .AddressForm__formGroup.AddressForm__address {
  grid-column: 1 / span 3;
  margin-bottom: 0;
}
.AddressForm
  .AddressForm__formGroup.AddressForm__address::-webkit-input-placeholder,
.AddressForm .AddressForm__formGroup.AddressForm__address::placeholder {
  font-family: var(--font-family-headings);
}
.AddressForm label.AddressForm__label {
  bottom: -10px;
  color: #fff;
  font-family: MetamorBit, Courier New, courier, monospace;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 140%;
  position: relative;
}
@media (min-width: 768px) {
  .AddressForm label.AddressForm__label {
    font-size: 0.875rem;
  }
}
@media (min-width: 1024px) {
  .AddressForm label.AddressForm__label {
    font-size: 0.875rem;
  }
}
.AddressForm .PlaceField__input.AddressForm__input {
  background: linear-gradient(270deg, #160fcc 50%, #f65137 0) 100%;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 200% 6px;
  border: none;
  border-radius: 0;
  color: #ffbf10;
  font-family: Obviously, sans-serif;
  font-family: var(--font-family-headings);
  font-size: 1.375rem;
  font-weight: 700;
  height: 70px;
  line-height: 125%;
  padding: 20px 0;
  transition: background 0.3s ease, color 0.3s ease;
}
@media (min-width: 768px) {
  .AddressForm .PlaceField__input.AddressForm__input {
    font-size: 1.75rem;
  }
}
@media (min-width: 1024px) {
  .AddressForm .PlaceField__input.AddressForm__input {
    font-size: 1.75rem;
  }
}
.AddressForm .PlaceField__input.AddressForm__input:active,
.AddressForm .PlaceField__input.AddressForm__input:focus {
  background-position: 0 100%;
}
.AddressForm .PlaceField__input.AddressForm__input:-webkit-autofill,
.AddressForm .PlaceField__input.AddressForm__input:-webkit-autofill:focus {
  background: linear-gradient(270deg, #ffbf10 50%, #f65137 0) 100%;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 200% 6px;
  border-bottom: 6px solid #f65137;
  transition: background-color 0s 600000s, color 0s 600000s;
}
.AddressForm button.AddressForm__submit {
  background-color: #f65137;
  border: 5px solid #f65137;
  border-radius: 0;
  clip-path: polygon(
    0 8px,
    8px 8px,
    8px 8px,
    8px 8px,
    8px 0,
    calc(100% - 8px) 0,
    calc(100% - 8px) 8px,
    calc(100% - 8px) 8px,
    calc(100% - 8px) 8px,
    100% 8px,
    100% calc(100% - 8px),
    calc(100% - 8px) calc(100% - 8px),
    calc(100% - 8px) calc(100% - 8px),
    calc(100% - 8px) calc(100% - 8px),
    calc(100% - 8px) 100%,
    8px 100%,
    8px calc(100% - 8px),
    8px calc(100% - 8px),
    8px calc(100% - 8px),
    0 calc(100% - 8px)
  );
  color: #fff;
  font-family: var(--font-family-headings);
  font-size: 1.25rem;
  font-weight: 900;
  line-height: 100%;
  margin-top: 25px;
  min-width: 125px;
  padding: 17px 21px 20px;
  text-align: center;
  transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
  width: fit-content;
}
.AddressForm button.AddressForm__submit:hover {
  background-color: #d03620;
  border-color: #d03620;
  opacity: 1;
}
.AddressForm .PlaceField__dropdown-container {
  color: #000;
  font-family: Obviously, sans-serif;
  font-family: var(--font-family-base);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 155%;
}
@media (min-width: 768px) {
  .AddressForm .PlaceField__dropdown-container {
    font-size: 1rem;
  }
}
@media (min-width: 1024px) {
  .AddressForm .PlaceField__dropdown-container {
    font-size: 1rem;
  }
}
/*# sourceMappingURL=ballotReady.css.map*/